amedia-menubar {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  max-width: 1015px;
  margin: 0 auto;
  min-height: 5px;
}

amedia-menubar ul {
  display: none;
}

amedia-menubar.visible ul {
  display: block;
}

@media screen and (min-width: 1024) {
  amedia-menubar.visible ul {
    display: block;
  }
}

.amedia-header .menubar ul {
  order: 3;
  flex-basis: 100%;
}

@media screen and (min-width: 1024px) {
  .amedia-header .menubar {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 0;
    padding: 0 20px 0 0;
  }

  .amedia-header .menubar > * {
    white-space: nowrap;
  }

  .amedia-header .menubar ul {
    justify-content: space-between;
    padding: 0;
    flex-basis: 50%;
  }
}

.amedia-header .menubar amedia-date {
  order: 1;
}

.amedia-header .menubar amedia-date time {
  color: #fff;
  height: 36px;
  display: flex;
  align-items: center;
}

.amedia-header.negative .menubar amedia-date time {
  color: #292827;
}

@media screen and (min-width: 1024px) {
  .amedia-header .menubar > ul {
    flex-grow: 1;
    order: 1;
    height: 100%;
  }

  .amedia-header .menubar amedia-date {
    order: 2;
  }

  .amedia-header .menubar amedia-menutrigger {
    order: 4;
  }

  .amedia-header .menubar {
    height: 42px;
  }

  .amedia-header .menubar > * {
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .amedia-header .menubar ul {
    background: #f6f5f1;
    margin: 0;
    padding: 0;
    padding-bottom: 12px;
  }

  .amedia-header .menubar ul a {
    border-top: 1px solid #e7e6e2;
    color: #292827;
    display: block;
    font-weight: 700;
    padding: 10px 18px;
    line-height: 24px;
  }

  .amedia-header .menubar ul li:last-child a {
    border-bottom: 1px solid #e7e6e2;
  }
}

.amedia-header .menubar li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1024px) {
  .amedia-header .menubar li {
    display: inline-block;
    height: 100%;
  }
}

.amedia-header .menubar a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0 15px;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
}

.amedia-header .menubar.light a {
  color: #292827;
}

.amedia-header .menubar a:hover {
  color: #292827;
}

amedia-menubar a:hover {
  background: #f6f5f1;
  color: #292827;
}

amedia-include.plussalt-button {
  display: block;
  position: absolute;
  top: 19px;
  right: 52px;
}

@media screen and (min-width: 1024px) {
  amedia-include.plussalt-button {
    position: relative;
    order: 3;
    top: unset;
    right: unset;
    height: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .amedia-header .menubar button {
    padding: 0;
  }
}
