.amedia-abomenu {
  font-family: 'Open Sans', sans-serif;
  display: block;
}

.amedia-abomenu.hide {
  display: none;
}

.amedia-abomenu ul {
  padding: 0;
  margin: 0;
}

.amedia-abomenu li {
  list-style: none;
}

.amedia-abomenu a {
  color: #292827;
  display: block;
  font-size: 15px;
  padding-top: 6px;
  text-decoration: none;
}

.amedia-abomenu a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .amedia-abomenu {
    background: #e7e6e2;
    color: #292827;
    padding: 15px;
    padding-bottom: 24px;
    margin-bottom: 12px;
  }

  .amedia-abomenu ul {
    margin-top: 6px;
    margin-bottom: 0;
  }
}

.amedia-abomenu a {
  cursor: pointer;
  font-size: 18px;
  line-height: 25px;
}
