foxfield-xmas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

amedia-branding {
  position: relative;
}
