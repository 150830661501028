.amedia-menu .classifieds {
  background: #e7e6e2;
  box-sizing: content-box;
  padding: 10px 0;
  width: 100%;
  position: relative;
  display: flex;
  min-height: 32px;
  align-items: center;
  flex-wrap: wrap;
}

.amedia-menu .classifieds::before {
  background: #e7e6e2;
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 2000px;
  z-index: 10;
}

.amedia-menu .classifieds::after {
  background: #e7e6e2;
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 2000px;
  z-index: 10;
}

.amedia-menu img.classifieds-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -7px;
}

.amedia-menu .classifieds a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-decoration: none;
  color: #292827;
  margin-right: 16px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.amedia-menu .classifieds a:hover {
  background: transparent;
}

.amedia-menu .classifieds h2 {
  display: inline-block;
  margin-right: 16px;
  padding: 0;
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: 20px;
}

@media screen and (min-width: 1024px) {
  .amedia-menu .classifieds {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
