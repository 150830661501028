.largeSearchBox {
  width: 100% !important;
}

.searchbox {
  display: block;
  background: transparent;
  margin-bottom: 10px;
}

.searchbox form {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.searchbox .front {
  flex-wrap: wrap;
}

.searchbox .front label {
  display: block;
  flex-basis: 100%;
  flex: 0 0 100%;
  margin-bottom: 10px;
}

.searchbox brick-input:first-child {
  flex-grow: 1;
}
.searchbox brick-input:last-child {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: auto;
}
