/* Innlogging aid ved lukket artikkel. Fjern kampanje */
.pushapp div.aid-incentive-overlay div.incentive-card.product-card,
.pushapp div.aid-incentive-overlay div.access-card.incentive-card {
  display: none !important;
}

/* ==== Meny === */
/* nord24/nordlys/iFinnmark/fremover */

.pushapp .share a[href*='/vis/personalia'],
.pushapp .customer-service a[href$='/vis/info/kontakt-kundesenteret'],
.pushapp .customer-service a[href$='/vis/info/kontakt-kundesenteret/'],
.pushapp .customer-service a[href$='/avisrubrikk'],
.pushapp .other_publications a[href^='http'],
.pushapp .atoz a[href*='/vis/personalia'],
.pushapp .customer-service a[href$='/vis/info/ansatte'],
.pushapp .customer-service a[href$='/abo/r/midlertidig-adresseendring'],
.pushapp .customer-service a[href$='/abo'],
.pushapp .customer-service a[href$='/avisrubrikk/'] {
  display: none !important;
}

.pushapp [href*='/abo/'] {
  display: none !important;
}

.pushapp [href$='/vis/info/qa'] {
  display: none !important;
}

/* More specific rule since we need to remove it from the menu, but not from teasers */
.pushapp [aria-label='Hovedmeny'] [href*='/vis/info/tips-oss'] {
  display: none !important;
}

.pushapp [href*='/vis/skrivinnlegg'] {
  display: none !important;
}

.pushapp [href$='/vis/info/abonnementspriser'] {
  display: none !important;
}

.pushapp [href^='https://play.google.com/']
{
  display: none !important;
}

.pushapp [href*='/kjop/produkter'] {
  display: none !important;
}
